import React from "react";
import { createRoot } from "react-dom/client";
import Home from "./pages/Home";
import "./styles.css";
import { SecretContext } from "./hooks/useSecret";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import MobileComponent from "./pages/Mobile"; // Import your mobile-specific component here

window.addEventListener(
  "keplr_keystorechange",
  () => {
    console.log("Key store in Keplr is changed. Refreshing page.");
    location.reload();
  },
  false,
);

// function to detect whether the user is on a mobile device
const isMobileDevice = () => {
  return (
    (typeof window !== "undefined" && window.innerWidth < 1024) ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  );
};

const rootElement = document.getElementById("app") as HTMLElement;
const root = createRoot(rootElement);

function App() {
  return (
    <>
      <Router>
        <SecretContext>
          <Routes>
            {/*<Route path="/login" element={<Login />} />*/}
            <Route
              path="/"
              element={
                isMobileDevice() ? (
                  <MobileComponent /> // Render mobile-specific component if the user is on a mobile device
                ) : (
                  <Home /> // Otherwise render the Home component
                )
              }
            />
          </Routes>
        </SecretContext>
      </Router>
    </>
  );
}
export default App;
root.render(<App />);
