// GreetingModal.tsx
import React from "react";
import Modal from "react-modal";
import "../styles.css";

// This line is required for accessibility reasons, and must be set to the id of the root element in your public/index.html file.
Modal.setAppElement("#app");

interface GreetingModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const GreetingModal: React.FC<GreetingModalProps> = ({
  isOpen,
  onRequestClose,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="game-modal-overlay"
      className="game-modal"
    >
      <h2>Secret VRF Roulette Bug Bounty Challenge!</h2>
      <p>
        Secret VRF, a secure and verifiable random number generator for Secret
        and the IBC ecosystem, recently went live on mainnet. Now, we’re putting
        it to the test.
      </p>
      <p>
        We’re offering up to $20,000 in SCRT to the first person that can find
        and demonstrate a bug that breaks the randomness provided by Secret VRF.
        For this challenge, SCRT Labs has built an on-chain roulette game.
      </p>
      <p>
        Play the game, view the contract code, and try to find a way to predict
        the number that{" "}
        <a
          href="https://docs.scrt.network/secret-network-documentation/development/secret-contract-fundamentals/available-native-features-modules/secret-vrf-on-chain-randomness"
          target="_blank"
        >
          Secret VRF
        </a>{" "}
        will provide. The bug bounty challenge ends on July 1st
      </p>
      <p>
        You can find the relevant code here:{" "}
        <a href="https://github.com/scrtlabs/secret-vrf-challenge-contract" target="_blank">
          Secret Contract
        </a>
        ,{" "}
        <a
          href="https://github.com/scrtlabs/tm-secret-enclave/blob/main/packages/enclave/src/lib.rs"
          target="_blank"
        >
          Random Generation
        </a>
        ,{" "}
        <a
          href="https://github.com/scrtlabs/SecretNetwork/blob/master/cosmwasm/enclaves/shared/block-verifier/src/submit_block_signatures.rs#L36"
          target="_blank"
        >
          Random Verification
        </a>
        .
      </p>
      <p>
        Submit any findings in this{" "}
        <a
          target="_blank"
          href="https://forms.monday.com/forms/07c4e4b4e25af0633d8efd2e8bc5e35d?r=use1"
        >
          {" "}
          Form
        </a>
        .
      </p>
      <button className="close" onClick={onRequestClose}>
        Close
      </button>
    </Modal>
  );
};

export default GreetingModal;
