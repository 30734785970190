import React from "react";
import Chip from "../Chip";
import { Guess } from "../utils/types";

function ChipComponent(props: {
  key: string;
  chipKey: string;
  guess: Guess;
  amount: number;
  rowSpan?: number | undefined;
  colSpan?: number | undefined;
  onClick: (item: Guess) => void;
}) {
  const { chipKey, guess, amount, rowSpan, colSpan } = props;

  const displayAmount = amount > 0 ? amount.toString() : "";

  const left = 0;
  const top = -15;

  const style = {
    top: top + "px",
    left: left + "px",
  };

  return (
    <td
      rowSpan={rowSpan}
      colSpan={colSpan}
      onClick={() => {
        console.log("click");
        props.onClick(guess);
      }}
    >
      <Chip amount={amount} guess={guess} rows={rowSpan} cols={colSpan} />
      <div key={chipKey} className={"chipValue"}>
        <div style={style} className={"chipSum"}>
          {displayAmount}
        </div>
      </div>
    </td>
  );
}

export default React.memo(ChipComponent);
