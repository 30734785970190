import anime from "animejs";
import React, { useEffect } from "react";
import { WheelNumber } from "../utils/types";
import { rouletteWheelNumbers } from "../utils/wheelParams";

const Wheel = (props: {
  number: WheelNumber;
  onSuccess: CallableFunction;
}): JSX.Element => {
  const totalNumbers = 37;
  const singleSpinDuration = 5000;
  const singleRotationDegree = 360 / totalNumbers;
  let lastNumber = 0;

  // console.log(props.number);
  const getRouletteIndexFromNumber = (number: string) => {
    return rouletteWheelNumbers.indexOf(parseInt(number));
  };
  const nextNumber = (number: number) => {
    return number;
  };
  const getRotationFromNumber = (number: string) => {
    const index = getRouletteIndexFromNumber(number);
    return singleRotationDegree * index;
  };

  // rotateTo randomizes the end outcome of the wheel,
  // so it doesn't only end at 0 at the top
  const getRandomEndRotation = (
    minNumberOfSpins: number,
    maxNumberOfSpins: number,
  ) => {
    const rotateTo = anime.random(
      minNumberOfSpins * totalNumbers,
      maxNumberOfSpins * totalNumbers,
    );

    return singleRotationDegree * rotateTo;
  };
  // calculating where the zero will be at the end of the spin
  // because we are spinning it counterclockwise we are subtracting it of 360
  const getZeroEndRotation = (totalRotation: number) => {
    return 360 - Math.abs(totalRotation % 360);
  };
  // Where the ball end position should be
  // we are calculating this based on the zero rotation
  // and how much the wheel spins
  const getBallEndRotation = (
    zeroEndRotation: number,
    currentNumber: string,
  ) => {
    return Math.abs(zeroEndRotation) + getRotationFromNumber(currentNumber);
  };
  // randomizing the number of spins that the ball should make
  // so every spin is different
  const getBallNumberOfRotations = (
    minNumberOfSpins: number,
    maxNumberOfSpins: number,
  ) => {
    return 360 * anime.random(minNumberOfSpins, maxNumberOfSpins);
  };

  function spinWheel(number: number, onSpinComplete: CallableFunction) {
    const bezier = [0.165, 0.84, 0.44, 1.005];
    const ballMinNumberOfSpins = 2;
    const ballMaxNumberOfSpins = 4;
    const wheelMinNumberOfSpins = 2;
    const wheelMaxNumberOfSpins = 4;

    const currentNumber = nextNumber(number);

    const lastNumberRotation = getRotationFromNumber(lastNumber.toString()); //anime.get(wheel, "rotate", "deg");

    // minus in front to reverse it so it spins counterclockwise
    const endRotation = -getRandomEndRotation(
      ballMinNumberOfSpins,
      ballMaxNumberOfSpins,
    );
    const zeroFromEndRotation = getZeroEndRotation(endRotation);
    const ballEndRotation =
      getBallNumberOfRotations(wheelMinNumberOfSpins, wheelMaxNumberOfSpins) +
      getBallEndRotation(zeroFromEndRotation, currentNumber.toString());

    // reset to the last number
    anime.set([".layer-2", ".layer-4"], {
      rotate: function () {
        return lastNumberRotation;
      },
    });
    // reset zero
    anime.set(".ball-container", {
      rotate: function () {
        return 0;
      },
    });

    anime({
      targets: [".layer-2", ".layer-4"],
      rotate: function () {
        return endRotation; // random number
      },
      duration: singleSpinDuration, // random duration
      easing: `cubicBezier(${bezier.join(",")})`,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      complete: function (_anim) {
        lastNumber = currentNumber;
      },
    });
    // animate ball
    anime({
      targets: ".ball-container",
      translateY: [
        { value: 0, duration: 2000 },
        { value: 20, duration: 1000 },
        { value: 25, duration: 900 },
        { value: 50, duration: 1000 },
      ],
      rotate: [{ value: ballEndRotation, duration: singleSpinDuration }],
      loop: 1,
      easing: `cubicBezier(${bezier.join(",")})`,
      complete: function () {
        lastNumber = currentNumber;
        onSpinComplete(); // call the callback function when spin is complete
      },
    });
  }

  useEffect(() => {
    const nextNumber = props.number.next;
    if (nextNumber != null && nextNumber !== "") {
      const nextNumberInt = parseInt(nextNumber);
      spinWheel(nextNumberInt, props.onSuccess);
    }
  }, [props.number]);

  return (
    <div className={"roulette-wheel"}>
      <div
        className={"layer-2 wheel"}
        style={{ transform: "rotate(0deg)" }}
      ></div>
      <div className={"layer-3"}></div>
      <div
        className={"layer-4 wheel"}
        style={{ transform: "rotate(0deg)" }}
      ></div>
      <div className={"layer-5"}></div>
      <div className={"ball-container"} style={{ transform: "rotate(0deg)" }}>
        <div
          className={"ball"}
          style={{ transform: "translate(0, -163.221px)" }}
        ></div>
      </div>
      {/* <svg width="380" height="380">
        <circle cx="190" cy="190" r="190" style={{touch-action: 'none'}}></circle>
      </svg> */}
    </div>
  );
};

export default Wheel;
