export enum GuessType {
  NUMBER,
  NUMBERS_1_12,
  NUMBERS_2_12,
  NUMBERS_3_12,
  NUMBERS_3_36,
  NUMBERS_2_35,
  NUMBERS_1_34,
  NUMBERS_1_18,
  NUMBERS_19_36,
  EVEN,
  ODD,
  RED,
  BLACK,
  DOUBLE_SPLIT,
  QUAD_SPLIT,
  TRIPLE_SPLIT,
  EMPTY,
}

export interface Guess {
  type: GuessType;
  value?: number;
  valueSplit?: number[];
}

export interface PlacedChip {
  item: Guess;
  sum: number;
}

export type Winner = {
  username: string;
  sum: number;
};
export type ChipsData = {
  selectedChip: number | undefined;
  placedChips: BetsOnTheBoard;
};

export class BetsOnTheBoard {
  public bets: Map<string, number>;

  constructor() {
    this.bets = new Map<string, number>();
  }

  get(item: Guess): number {
    return this.bets.get(this.guessToString(item)) || 0;
  }

  total(): number {
    let sum = 0;
    for (const arr of this.bets.values()) {
      sum += arr;
    }

    for (const arr of this.bets.keys()) {
      console.log(`key: ${JSON.stringify(arr)}`);
    }

    return sum;
  }

  *entries(): IterableIterator<[Guess, number]> {
    for (const [key, value] of this.bets.entries()) {
      const newKey = this.stringToGuess(key);
      yield [newKey, value];
    }
  }

  private guessToString = (guess: Guess) => {
    return JSON.stringify(guess);
    // return `${guess.type}_${guess.value || ""}_${guess.valueSplit || ""}`;
  };

  private stringToGuess = (key: string): Guess => {
    return JSON.parse(key);
    // const fields = key.split("_");
    //
    // if (fields[1] === "") {
    //   return { type: Number(fields[0]) };
    // } else if (fields[2] === "") {
    //   return { type: Number(fields[0]), value: Number(fields[1]) };
    // }
    // return {
    //   type: Number(fields[0]),
    //   value: Number(fields[1]),
    //   valueSplit: fields[2].split(",").map(Number),
    // };
  };
  // appendBet(guess: Guess, value: number) {
  //   let cur = this.bets.get(guess) || 0;
  //   cur += value;
  //   this.bets.set(guess, cur);
  //
  //
  // }

  appendBet(item: Guess, value: number): BetsOnTheBoard {
    // create a new instance of BetsOnTheBoard with the updated state

    const newBetsOnTheBoard = new BetsOnTheBoard();
    newBetsOnTheBoard.bets = new Map(this.bets);

    // update the bet for the given item
    const currentBet =
      newBetsOnTheBoard.bets.get(this.guessToString(item)) ?? 0;
    newBetsOnTheBoard.bets.set(this.guessToString(item), currentBet + value);

    return newBetsOnTheBoard;
  }
}

export type WheelNumber = {
  next: string | undefined;
};

export enum GameStages {
  PLACE_BET,
  NO_MORE_BETS,
  BALL_ROLLING,
  SHOW_RESULT,
  WINNERS,
  NONE,
}
export type GameData = {
  stage: GameStages;
  time_remaining: number;
  value: number;
  wins: Winner[];
  history: number[];
};
