import React from "react";
import ComingSoonImage from "../assets/mobile-soon.webp"; // replace this with your actual image path

const MobilePlaceholder = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img
        src={ComingSoonImage}
        alt="Coming soon"
        style={{ maxWidth: "100%" }}
      />
    </div>
  );
};

export default MobilePlaceholder;
