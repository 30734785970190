import { bech32 } from "bech32";

export const convertBech32 = (address: string, toHrp: string): string => {
  const { words } = bech32.decode(address);
  return bech32.encode(toHrp, words);
};

export const toDisplayAddress = (address: string | undefined) => {
  if (!address) {
    return;
  }
  if (address.startsWith("Connect")) {
    return address;
  }
  return `${address.slice(0, 9)}..${address.slice(-3, address.length)}`;
};
