import { Guess, GuessType } from "../utils/types";

interface chipType {
  type: GuessType;
  className: string;
  size: {
    rowSpan: number;
    colSpan: number;
  };
}

export const boardNumberRows: Guess[][] = [
  [
    { type: GuessType.NUMBER, value: 0 },
    // { type: GuessType.DOUBLE_SPLIT, valueSplit: [0, 3] },
    { type: GuessType.EMPTY },
    { type: GuessType.NUMBER, value: 3 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [3, 6] },
    { type: GuessType.NUMBER, value: 6 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [6, 9] },
    { type: GuessType.NUMBER, value: 9 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [9, 12] },
    { type: GuessType.NUMBER, value: 12 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [12, 15] },
    { type: GuessType.NUMBER, value: 15 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [15, 18] },
    { type: GuessType.NUMBER, value: 18 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [18, 21] },
    { type: GuessType.NUMBER, value: 21 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [21, 24] },
    { type: GuessType.NUMBER, value: 24 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [24, 27] },
    { type: GuessType.NUMBER, value: 27 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [27, 30] },
    { type: GuessType.NUMBER, value: 30 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [30, 33] },
    { type: GuessType.NUMBER, value: 33 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [33, 36] },
    { type: GuessType.NUMBER, value: 36 },
    { type: GuessType.EMPTY },
    { type: GuessType.NUMBERS_3_36 },
  ],
  [
    // { type: GuessType.TRIPLE_SPLIT, valueSplit: [0, 3, 2] },
    { type: GuessType.EMPTY },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [3, 2] },
    { type: GuessType.QUAD_SPLIT, valueSplit: [3, 6, 2, 5] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [6, 5] },
    { type: GuessType.QUAD_SPLIT, valueSplit: [6, 9, 5, 8] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [9, 8] },
    { type: GuessType.QUAD_SPLIT, valueSplit: [9, 12, 8, 11] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [12, 11] },
    { type: GuessType.QUAD_SPLIT, valueSplit: [12, 15, 11, 14] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [15, 14] },
    { type: GuessType.QUAD_SPLIT, valueSplit: [15, 18, 14, 17] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [18, 17] },
    { type: GuessType.QUAD_SPLIT, valueSplit: [18, 21, 17, 20] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [21, 20] },
    { type: GuessType.QUAD_SPLIT, valueSplit: [21, 24, 20, 23] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [24, 23] },
    { type: GuessType.QUAD_SPLIT, valueSplit: [24, 27, 23, 26] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [27, 26] },
    { type: GuessType.QUAD_SPLIT, valueSplit: [27, 30, 26, 29] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [30, 29] },
    { type: GuessType.QUAD_SPLIT, valueSplit: [30, 33, 29, 32] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [33, 32] },
    { type: GuessType.QUAD_SPLIT, valueSplit: [33, 36, 32, 35] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [36, 35] },
    { type: GuessType.EMPTY },
    { type: GuessType.EMPTY },
  ],
  [
    // { type: GuessType.DOUBLE_SPLIT, valueSplit: [0, 3] },
    { type: GuessType.EMPTY },
    { type: GuessType.NUMBER, value: 2 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [2, 5] },
    { type: GuessType.NUMBER, value: 5 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [5, 8] },
    { type: GuessType.NUMBER, value: 8 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [8, 11] },
    { type: GuessType.NUMBER, value: 11 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [11, 14] },
    { type: GuessType.NUMBER, value: 14 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [14, 17] },
    { type: GuessType.NUMBER, value: 17 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [17, 20] },
    { type: GuessType.NUMBER, value: 20 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [20, 23] },
    { type: GuessType.NUMBER, value: 23 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [23, 26] },
    { type: GuessType.NUMBER, value: 26 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [26, 29] },
    { type: GuessType.NUMBER, value: 29 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [29, 32] },
    { type: GuessType.NUMBER, value: 32 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [32, 35] },
    { type: GuessType.NUMBER, value: 35 },
    { type: GuessType.EMPTY },
    { type: GuessType.NUMBERS_2_35 },
  ],
  [
    { type: GuessType.EMPTY },
    // { type: GuessType.TRIPLE_SPLIT, valueSplit: [0, 2, 1] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [2, 1] },
    { type: GuessType.QUAD_SPLIT, valueSplit: [2, 5, 1, 4] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [5, 4] },
    { type: GuessType.QUAD_SPLIT, valueSplit: [5, 8, 4, 7] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [8, 7] },
    { type: GuessType.QUAD_SPLIT, valueSplit: [8, 11, 7, 10] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [11, 10] },
    { type: GuessType.QUAD_SPLIT, valueSplit: [11, 14, 10, 13] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [14, 13] },
    { type: GuessType.QUAD_SPLIT, valueSplit: [14, 17, 13, 16] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [17, 16] },
    { type: GuessType.QUAD_SPLIT, valueSplit: [17, 20, 16, 19] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [20, 19] },
    { type: GuessType.QUAD_SPLIT, valueSplit: [20, 23, 19, 22] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [23, 22] },
    { type: GuessType.QUAD_SPLIT, valueSplit: [23, 26, 22, 25] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [26, 25] },
    { type: GuessType.QUAD_SPLIT, valueSplit: [26, 29, 25, 28] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [29, 28] },
    { type: GuessType.QUAD_SPLIT, valueSplit: [29, 32, 28, 31] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [32, 31] },
    { type: GuessType.QUAD_SPLIT, valueSplit: [32, 35, 31, 34] },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [35, 34] },
    { type: GuessType.EMPTY },
    { type: GuessType.EMPTY },
  ],
  [
    // { type: GuessType.DOUBLE_SPLIT, valueSplit: [0, 3] },
    { type: GuessType.EMPTY },
    { type: GuessType.NUMBER, value: 1 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [1, 4] },
    { type: GuessType.NUMBER, value: 4 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [4, 7] },
    { type: GuessType.NUMBER, value: 7 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [7, 10] },
    { type: GuessType.NUMBER, value: 10 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [10, 13] },
    { type: GuessType.NUMBER, value: 13 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [13, 16] },
    { type: GuessType.NUMBER, value: 16 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [16, 19] },
    { type: GuessType.NUMBER, value: 19 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [19, 22] },
    { type: GuessType.NUMBER, value: 22 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [22, 25] },
    { type: GuessType.NUMBER, value: 25 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [25, 28] },
    { type: GuessType.NUMBER, value: 28 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [28, 31] },
    { type: GuessType.NUMBER, value: 31 },
    { type: GuessType.DOUBLE_SPLIT, valueSplit: [31, 34] },
    { type: GuessType.NUMBER, value: 34 },
    { type: GuessType.EMPTY },
    { type: GuessType.NUMBERS_1_34 },
  ],
];

export const specialBetsRowUpper: chipType[] = [
  {
    type: GuessType.NUMBERS_1_12,
    className: "other_1_12",
    size: {
      rowSpan: 1,
      colSpan: 8,
    },
  },
  {
    type: GuessType.NUMBERS_2_12,
    className: "other_2_12",
    size: {
      rowSpan: 1,
      colSpan: 8,
    },
  },
  {
    type: GuessType.NUMBERS_3_12,
    className: "other_3_12",
    size: {
      rowSpan: 1,
      colSpan: 8,
    },
  },
];
export const specialBetsRowLower: chipType[] = [
  {
    type: GuessType.NUMBERS_1_18,
    className: "other_1_18",
    size: {
      rowSpan: 1,
      colSpan: 4,
    },
  },
  {
    type: GuessType.EVEN,
    className: "other_even",
    size: {
      rowSpan: 1,
      colSpan: 4,
    },
  },
  {
    type: GuessType.RED,
    className: "other_red",
    size: {
      rowSpan: 1,
      colSpan: 4,
    },
  },
  {
    type: GuessType.BLACK,
    className: "other_black",
    size: {
      rowSpan: 1,
      colSpan: 4,
    },
  },
  {
    type: GuessType.ODD,
    className: "other_odd",
    size: {
      rowSpan: 1,
      colSpan: 4,
    },
  },
  {
    type: GuessType.NUMBERS_19_36,
    className: "other_19_36",
    size: {
      rowSpan: 1,
      colSpan: 4,
    },
  },
];
