import Wheel from "../components/Wheel";
import Board from "../components/Board";
import { Button } from "@mantine/core";
import { BetsOnTheBoard, GameStages, Guess, WheelNumber } from "../utils/types";
import classNames from "classnames";

import Tooltip from "@mui/material/Tooltip";

import React, { useEffect, useState } from "react";
import { FAUCET_ENDPOINT, useSecret } from "../hooks/useSecret";
import { placeBetToContract } from "../contracts/contract";
import { blackNumbers, chipDenoms } from "../utils/wheelParams";
import { TxResponse } from "secretjs";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // import the css, if you haven't done it yet

import {
  parseWinnerFromResponse,
  parseWinningBetsFromResponse,
  WinningBet,
} from "../scrt/utils";
import GameModal from "../components/ResultModal";
import Account from "../components/Account";
import GetFromFaucet from "../components/GetFromFaucet";
import GreetingModal from "../components/DisclaimerPopup";
import TermsAndConditions from "../components/TermsAndConditions";
import { IconButton } from "@mui/material";
import { ReactComponent as HelpIcon } from "../assets/help_outline.svg";

const chipClassNames: Record<number, string> = {
  100: "chip-100",
  50: "chip-50",
  20: "chip-20",
  10: "chip-10",
  5: "chip-5",
};

const Home: React.FC = () => {
  const { account, scrtBalance, chainId, secretjs, refreshBalances } =
    useSecret();

  const [selectedChip, setSelectedChip] = useState<number | undefined>(
    undefined,
  );
  const [placedChips, setPlacedChips] = useState<BetsOnTheBoard>(
    new BetsOnTheBoard(),
  );
  const [winningNumber, setWinningNumber] = useState<WheelNumber>({
    next: undefined,
  });
  // const [winners, setWinners] = useState<{ username: string; sum: number }[]>(
  //   [],
  // );
  const [history, setHistory] = useState<number[]>([]);
  const [winningBets, setWinningBets] = useState<WinningBet[]>([]);

  const [stage, setStage] = useState<GameStages>(GameStages.PLACE_BET);

  const [loadingFromFaucet, setLoadingFromFaucet] = useState<boolean>(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [termsAreOpen, setTermsAreOpen] = useState(false);

  useEffect(() => {
    const alreadyVisited = localStorage.getItem("alreadyVisited");
    if (!alreadyVisited) {
      setModalIsOpen(true);
      localStorage.setItem("alreadyVisited", "true");
    }
  }, []);

  const closeGreetingModal = () => {
    setModalIsOpen(false);
  };

  const onChipClick = (chip: number | undefined) => {
    if (chip !== undefined) {
      setSelectedChip(chip);
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timer | undefined;

    if (loadingFromFaucet) {
      interval = setInterval(async () => {
        await refreshBalances();
      }, 2000); // Change this value to adjust the interval (in milliseconds)
    }

    return () => {
      if (interval) {
        clearInterval(interval);
        setLoadingFromFaucet(false);
      }
    };
  }, [loadingFromFaucet, scrtBalance]);

  useEffect(() => {
    const fn = async () => {
      if (loadingFromFaucet && account) {
        await fetch(`${FAUCET_ENDPOINT}/faucet?address=${account}`);
      }
    };

    fn();
  }, [loadingFromFaucet]);

  const onCellClick = (item: Guess) => {
    if (selectedChip === 0 || selectedChip === undefined) {
      return;
    }

    setPlacedChips((prevState) => {
      return prevState.appendBet(item, selectedChip);
    });
  };

  const getChipClasses = (chip: number) => {
    return classNames({
      chip_selected: chip === selectedChip,
      [chipClassNames[chip]]: true,
    });
  };

  const placeBet = async () => {
    if (!secretjs) {
      throw new Error("Must have a connected wallet");
    }

    // console.log(`placed chips: ${JSON.stringify(placedChips)}`);

    setStage(GameStages.NO_MORE_BETS);

    await placeBetToContract(
      secretjs,
      placedChips,
      async (tx: TxResponse) => {
        // console.log(`result: ${JSON.stringify(tx)}`);
        const winningNumber = parseWinnerFromResponse(tx);

        if (winningNumber) {
          setStage(GameStages.BALL_ROLLING);
          setWinningNumber({ next: winningNumber });

          const winningBets = parseWinningBetsFromResponse(tx);

          if (winningBets.length > 0) {
            // console.log(`got winners: ${JSON.stringify(winningBets)}`);
            // console.log(`setting bets`);
            setWinningBets(winningBets);
          }
        }
      },
      (e: Error) => {
        toast.error(e.message);
        setStage(GameStages.PLACE_BET);
      },
    );
  };

  const onDoneRollingBall = () => {
    if (winningNumber.next) {
      const currentHistory = history;
      currentHistory.push(Number(winningNumber.next));
      setHistory(currentHistory);
      setPlacedChips(new BetsOnTheBoard());
      setStage(GameStages.SHOW_RESULT);
    }
  };

  const clearBet = () => {
    setPlacedChips(new BetsOnTheBoard());
  };

  return (
    <div>
      <ToastContainer />
      <div>
        <GreetingModal
          isOpen={modalIsOpen}
          onRequestClose={closeGreetingModal}
        />
        <GameModal
          isOpen={stage === GameStages.SHOW_RESULT}
          onRequestClose={() => {
            refreshBalances();
            console.log(`winningbets: ${winningBets}`);
            setStage(GameStages.PLACE_BET);
            setWinningBets([]);
          }}
          winningBets={winningBets}
        />
        {/*<h1 style={{dis}}>Secret VRF Roulette Bug Bounty Challenge</h1>*/}
        <table className={"rouletteWheelWrapper"}>
          <tbody>
            <tr className={"walletInfo"}>
              <td>
                {chainId !== "secret-4" ? (
                  <GetFromFaucet
                    loadingFromFaucet={loadingFromFaucet}
                    setLoadingFromFaucet={setLoadingFromFaucet}
                  />
                ) : (
                  <Account
                    address={account || ""}
                    balance={Number(scrtBalance)}
                  />
                )}
              </td>
              <td>
                {chainId !== "secret-4" ? (
                  <Account
                    address={account || ""}
                    balance={Number(scrtBalance)}
                  />
                ) : (
                  <h1>Secret VRF Roulette Bug Bounty Challenge</h1>
                )}
              </td>
              <td style={{ paddingTop: "20px" }}>
                <Tooltip title="What is the bug bounty challenege?">
                  <Button
                    onClick={() => {
                      setModalIsOpen(true);
                    }}
                    variant="gradient"
                    gradient={{
                      from: "#ed6ea0",
                      to: "#ec8c69",
                      deg: 35,
                    }}
                    size="md"
                    style={{ borderRadius: "20px" }}
                  >
                    Learn More
                  </Button>
                </Tooltip>
              </td>
            </tr>
            <tr>
              <td className={"winnersBoard"}>
                {/*<div className={"winnerItemHeader hideElementsTest"}>*/}
                {/*  WINNERS*/}
                {/*</div>*/}
                {/*{winners.map(({ username, sum }, index) => {*/}
                {/*  return (*/}
                {/*    <div className="winnerItem">*/}
                {/*      {index + 1}. {username} won {sum}$*/}
                {/*    </div>*/}
                {/*  );*/}
                {/*})}*/}
              </td>
              <td>
                <Wheel number={winningNumber} onSuccess={onDoneRollingBall} />
              </td>
              <td>
                <div className={"winnerHistory hideElementsTest"}>
                  {history.map((entry) => {
                    if (entry === 0) {
                      return <div className="green">{entry}</div>;
                    } else if (blackNumbers.includes(entry)) {
                      return <div className="black">{entry}</div>;
                    } else {
                      return <div className="red">{entry}</div>;
                    }
                  })}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <Board onCellClick={onCellClick} placedChips={placedChips} />
      </div>
      <div className="roulette-actions hideElementsTest">
        <ul>
          <li>
            <Button
              variant="gradient"
              gradient={{
                from: "#ed6ea0",
                to: "#ec8c69",
                deg: 35,
              }}
              size="xl"
              onClick={() => clearBet()}
            >
              Clear Bet
            </Button>
          </li>
          {chipDenoms.map((value) => {
            return (
              <li className={"board-chip"} key={`chip_${value}`}>
                <div
                  key={`chip_${value}`}
                  className={getChipClasses(value)}
                  onClick={() => onChipClick(value)}
                >
                  {value}
                </div>
              </li>
            );
          })}
          <li>
            <Button
              disabled={stage !== GameStages.PLACE_BET}
              variant="gradient"
              gradient={{ from: "orange", to: "red" }}
              size="xl"
              onClick={placeBet}
            >
              Place Bet
            </Button>
          </li>
        </ul>
      </div>
      <div
        style={{
          display: "block",
          position: "relative",
          marginTop: "20px",
        }}
      >
        <ul style={{ marginTop: "-20px" }}>
          <div style={{ display: "flex" }}>
            <p style={{ paddingTop: "2px" }}>Powered by Secret Network</p>
            <button
              className="button-style"
              onClick={() => {
                setTermsAreOpen(true);
              }}
            >
              Terms and Conditions
            </button>
          </div>
        </ul>
        <TermsAndConditions
          isOpen={termsAreOpen}
          onRequestClose={() => {
            setTermsAreOpen(false);
          }}
        />
      </div>
    </div>
  );
};

export default Home;
