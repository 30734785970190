import React from "react";
import "../styles.css";

interface GetScrtButtonProps {
  loadingFromFaucet: boolean;
  setLoadingFromFaucet: (loading: boolean) => void;
}

const GetScrtButton: React.FC<GetScrtButtonProps> = ({
  loadingFromFaucet,
  setLoadingFromFaucet,
}) => {
  const handleClick = () => {
    if (loadingFromFaucet) {
      setLoadingFromFaucet(false);
    }
    setLoadingFromFaucet(true);
  };

  return (
    <button className="get-scrt-button" onClick={handleClick}>
      <span className="material-icons">attach_money</span>
      Get SCRT
    </button>
  );
};

export default GetScrtButton;
