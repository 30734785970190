import React from "react";
import { BetsOnTheBoard, Guess, GuessType } from "../utils/types";
import ChipComponent from "./ChipComponent";
import {
  specialBetsRowUpper,
  specialBetsRowLower,
  boardNumberRows,
} from "./types";

type BoardProps = {
  onCellClick: (item: Guess) => void;
  placedChips: BetsOnTheBoard;
};

const Board: React.FC<BoardProps> = ({ onCellClick, placedChips }) => {
  // console.log(`total: ${placedChips.total()}`);

  return (
    <div className="roulette-board-wrapper hideElementsTest">
      <div className="roulette-board">
        <div className="roulette-board-grid-numbers">
          <table>
            <tbody>
              {boardNumberRows.map((boardRow, index) => {
                return (
                  <tr key={"tr_board_" + index}>
                    {boardRow.map((boardItem, guessIndex) => {
                      let chipKeyValue = boardItem.value?.toString();
                      if (boardItem.value === undefined) {
                        const split = boardItem.valueSplit + "";
                        chipKeyValue = "split_" + split;
                      }
                      const tdKey = "td_" + boardItem.type + "_" + chipKeyValue;

                      const chipKey =
                        "chip_" + boardItem.type + "_" + chipKeyValue;

                      if (boardItem.type === GuessType.EMPTY) {
                        return <td key={"empty_" + index + guessIndex}></td>;
                      } else {
                        const sumChipsInCell = placedChips.get(boardItem);
                        return (
                          <ChipComponent
                            amount={sumChipsInCell}
                            key={tdKey}
                            chipKey={chipKey}
                            guess={boardItem}
                            rowSpan={boardItem.value === 0 ? 5 : 1}
                            colSpan={1}
                            onClick={onCellClick}
                          />
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="roulette-board-grid-other">
          <table>
            <tbody>
              {[specialBetsRowUpper, specialBetsRowLower].map(
                (boardRow, index) => {
                  return (
                    <tr key={`bottom_row_${index}`}>
                      <td colSpan={2} />
                      {boardRow.map(({ type, className, size }) => {
                        // console.log(
                        //   `drawing: ${type}, classname: ${className}`
                        // );
                        const amount = placedChips.get({ type });
                        return (
                          <ChipComponent
                            amount={amount}
                            key={`td_${className}`}
                            chipKey={`chip_${className}`}
                            guess={{ type }}
                            {...size}
                            onClick={onCellClick}
                          />
                        );
                      })}
                      <td colSpan={2} />
                    </tr>
                  );
                },
              )}
              <tr>
                {[...Array(27)].map((_, i) => (
                  <td key={i} />
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Board;
