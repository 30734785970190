import { SecretNetworkClient, TxResponse, TxResultCode } from "secretjs";
import { CONTRACT_ADDRESS, CONTRACT_HASH } from "../hooks/useSecret";
import { Coin } from "secretjs";
import { BetsOnTheBoard, GuessType, PlacedChip } from "../utils/types";
// import {newListener} from "./websocket";

// const waitForSpinTx = (key: string) => {
//   let ws = newListener();
// };

const appToContractResult = (result: PlacedChip): Bet => {
  const amount = { denom: "uscrt", amount: result.sum.toString() }; //  * 10**6

  if (Number(amount.amount) > Number(process.env.REACT_APP_MAX_BET)) {
    throw new Error(
      `Cannot bet more than table limit of ${process.env.REACT_APP_MAX_BET}uscrt`,
    );
  }
  switch (result.item.type) {
    case GuessType.BLACK:
      return {
        amount,
        result: "black",
      };
    case GuessType.DOUBLE_SPLIT:
      if (result.item.valueSplit) {
        return {
          amount,
          result: {
            line: {
              nums: [result.item.valueSplit[0], result.item.valueSplit[1]],
            },
          },
        };
      } else {
        console.log(`result: ${JSON.stringify(result.item)}`);
        throw new Error("Double split has to have numbers");
      }
    case GuessType.EMPTY:
      throw new Error("Don't spin without a bet dawg");
    case GuessType.EVEN:
      return {
        amount,
        result: "even",
      };
    case GuessType.NUMBER:
      return {
        amount,
        result: { exact: { num: result.item.value } },
      };
    case GuessType.NUMBERS_1_12:
      return {
        amount,
        result: "range1to12",
      };
    case GuessType.NUMBERS_1_18:
      return {
        amount,
        result: "range1to18",
      };
    case GuessType.NUMBERS_2_12:
      return {
        amount,
        result: "range13to24",
      };
    case GuessType.NUMBERS_3_12:
      return {
        amount,
        result: "range25to36",
      };
    case GuessType.NUMBERS_19_36:
      return {
        amount,
        result: "range19to36",
      };
    case GuessType.NUMBERS_3_36:
      return {
        amount,
        result: "range2to1_third",
      };
    case GuessType.NUMBERS_2_35:
      return {
        amount,
        result: "range2to1_second",
      };
    case GuessType.NUMBERS_1_34:
      return {
        amount,
        result: "range2to1_first",
      };
    case GuessType.ODD:
      return {
        amount,
        result: "odd",
      };
    case GuessType.QUAD_SPLIT:
      if (result.item.valueSplit) {
        return {
          amount,
          result: {
            corner: {
              nums: [
                result.item.valueSplit[0],
                result.item.valueSplit[1],
                result.item.valueSplit[2],
                result.item.valueSplit[3],
              ],
            },
          },
        };
      } else {
        throw new Error("Quad split has to have 4 numbers");
      }
    case GuessType.RED:
      return {
        amount,
        result: "red",
      };
    case GuessType.TRIPLE_SPLIT:
      throw new Error("Unsupported go away");
    // return {
    //   amount,
    //   result: "black",
    // };
  }
};

export interface Bet {
  amount: Coin;
  result: string | object;
}

export class PlaceBetMsg {
  bets: Bet[];

  constructor(guesses: BetsOnTheBoard) {
    this.bets = [];

    for (const [guess, sum] of guesses.entries()) {
      this.bets.push(appToContractResult({ item: guess, sum: sum }));
    }
  }

  to_msg() {
    return {
      bet: {
        bets: this.bets,
      },
    };
  }
}

export const placeBetToContract = async (
  sender: SecretNetworkClient,
  bets: BetsOnTheBoard,
  // onLoading?: CallableFunction,
  onSuccess?: CallableFunction,
  onFail?: CallableFunction,
) => {
  let msg = undefined;
  try {
    msg = new PlaceBetMsg(bets).to_msg();
  } catch (e) {
    if (onFail) {
      onFail(e);
    }
    return;
  }

  const totalBets = bets.total();

  console.log(`sending: ${JSON.stringify(msg)}`);

  return sender.tx.compute
    .executeContract(
      {
        code_hash: CONTRACT_HASH,
        contract_address: CONTRACT_ADDRESS,
        msg,
        sender: sender.address,
        sent_funds: [{ amount: totalBets.toString(), denom: "uscrt" }], //  * 10 ** 6
      },
      {
        gasLimit: 150_000,
      },
    )
    .then((response: TxResponse) => {
      if (response.code === TxResultCode.Success) {
        if (onSuccess) {
          onSuccess(response);
        }
      } else {
        if (onFail) {
          onFail(new Error(`Failed to send: ${response.rawLog}`));
        }
      }
    })
    .catch((e: Error) => {
      if (onFail) {
        onFail(e);
      } else {
        throw new Error(e.message);
      }
    });
};
