import { CHAIN_ID, CHAIN_NAME, LCD_ENDPOINT, RPC_ENDPOINT } from "../useSecret";
import { sleep } from "../../utils/functions";
// import { Keplr } from "@keplr-wallet/types";

export const setupKeplrCustomChain = async () => {
  if (!window || !window.keplr) {
    await sleep(50);
  }

  try {
    await window.keplr.getKey(CHAIN_ID);
  } catch (e) {
    // this is just here to wait for wallet unlock
  }

  await window.keplr.experimentalSuggestChain({
    chainId: CHAIN_ID,
    chainName: CHAIN_NAME,
    rpc: RPC_ENDPOINT,
    rest: LCD_ENDPOINT,
    bip44: {
      coinType: 529,
    },
    coinType: 529,
    stakeCurrency: {
      coinDenom: "SCRT",
      coinMinimalDenom: "uscrt",
      coinDecimals: 6,
    },
    bech32Config: {
      bech32PrefixAccAddr: "secret",
      bech32PrefixAccPub: "secretpub",
      bech32PrefixValAddr: "secretvaloper",
      bech32PrefixValPub: "secretvaloperpub",
      bech32PrefixConsAddr: "secretvalcons",
      bech32PrefixConsPub: "secretvalconspub",
    },
    currencies: [
      {
        coinDenom: "SCRT",
        coinMinimalDenom: "uscrt",
        coinDecimals: 6,
      },
    ],
    feeCurrencies: [
      {
        coinDenom: "SCRT",
        coinMinimalDenom: "uscrt",
        coinDecimals: 6,
        gasPriceStep: {
          low: 0.01,
          average: 0.25,
          high: 0.25,
        },
      },
    ],

    features: ["secretwasm"],
  });
};
