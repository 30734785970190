import React from "react";
import { Guess } from "./utils/types";
import { chipDisplayDenoms } from "./utils/wheelParams";
import { getChipClasses } from "./utils/classes";
import { randomNumber } from "./utils/random";

const Chip = function (props: {
  amount: number;
  guess: Guess;
  rows?: number;
  cols?: number;
}): JSX.Element {
  const { guess, amount, cols, rows } = props;

  const chipsImgs: JSX.Element[] = [];

  const numberOfChips = (amount: number, index?: number): number[] => {
    if (amount === 0) {
      return [];
    }

    const div = amount / chipDisplayDenoms[index || 0];
    const remainder = amount % chipDisplayDenoms[index || 0];

    return [Math.trunc(div), ...numberOfChips(remainder, (index || 0) + 1)];
  };

  if (amount !== 0) {
    const chipsToDisplay = numberOfChips(amount);

    console.log(
      `Amount displaying: ${amount} - ${JSON.stringify(chipsToDisplay)}`,
    );

    chipsToDisplay.map((num, index) => {
      const chipDenom = chipDisplayDenoms[index];
      for (let i = 0; i < num; i++) {
        chipsImgs.push(
          <div
            key={`${guess.type}_${guess.value}_${chipDenom}_${i}`}
            style={{
              left: `${(cols || 0) * 10 + randomNumber(-10, 10)}px`,
              top: `${(rows || 0) + randomNumber(-30, 0)}px`,
            }}
            className={getChipClasses(chipDenom)}
          ></div>,
        );
      }
    });
    return <div className={"chipValue"}>{chipsImgs}</div>;
  } else {
    return <></>;
  }
};

export default React.memo(Chip);
