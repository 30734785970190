import { Player } from "@lottiefiles/react-lottie-player";
import robotAnimation from "../../assets/87253-confet.json";
import { CSSProperties } from "react";

const ConfettiAnimation = (props: { style?: CSSProperties }) => {
  return (
    <Player
      src={robotAnimation}
      className="player"
      loop
      autoplay
      style={props.style}
    />
  );
};

export default ConfettiAnimation;
