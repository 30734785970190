import classNames from "classnames";
import { GuessType } from "./types";
import { rouletteWheelNumbers } from "./wheelParams";

export function getChipClasses(chip: number) {
  return classNames({
    "chip-100-placed": chip === 100,
    "chip-50-placed": chip === 50,
    "chip-20-placed": chip === 20,
    "chip-10-placed": chip === 10,
    "chip-5-placed": chip === 5,
    chipValueImage: true,
  });
}

// getCellItemFromCellItemType(type: any) {}
export function getClassNamesFromCellItemType(
  type: GuessType,
  number: number | undefined,
) {
  let isEvenOdd = 0;
  if (number != null && type === GuessType.NUMBER && number !== 0) {
    if (number % 2 === 0) {
      isEvenOdd = 1;
    } else {
      isEvenOdd = 2;
    }
  }
  //const numberValue = "value-" + number;
  return classNames({
    //[`${numberValue}`]: true,
    "board-cell-number": type === GuessType.NUMBER,
    "board-cell-double-split": type === GuessType.DOUBLE_SPLIT,
    "board-cell-quad-split": type === GuessType.QUAD_SPLIT,
    "board-cell-triple-split": type === GuessType.TRIPLE_SPLIT,
    "board-cell-empty": type === GuessType.EMPTY,
    "board-cell-even": type === GuessType.EVEN || isEvenOdd === 1,
    "board-cell-odd": type === GuessType.ODD || isEvenOdd === 2,
    "board-cell-number-1-18":
      type === GuessType.NUMBERS_1_18 ||
      (number !== undefined &&
        number >= 1 &&
        number <= 18 &&
        type === GuessType.NUMBER),
    "board-cell-number-19-36":
      type === GuessType.NUMBERS_19_36 ||
      (number !== undefined &&
        number >= 19 &&
        number <= 36 &&
        type === GuessType.NUMBER),
    "board-cell-number-1-12":
      type === GuessType.NUMBERS_1_12 ||
      (number !== undefined &&
        number % 3 === 0 &&
        type === GuessType.NUMBER &&
        number !== 0),
    "board-cell-number-2-12":
      type === GuessType.NUMBERS_2_12 ||
      (number !== undefined && number % 3 === 2 && type === GuessType.NUMBER),
    "board-cell-number-3-12":
      type === GuessType.NUMBERS_3_12 ||
      (number !== undefined && number % 3 === 1 && type === GuessType.NUMBER),
    "board-cell-red":
      type === GuessType.RED ||
      (number !== undefined &&
        getRouletteColor(number) === "red" &&
        type === GuessType.NUMBER),
    "board-cell-black":
      type === GuessType.BLACK ||
      (number !== undefined &&
        getRouletteColor(number) === "black" &&
        type === GuessType.NUMBER),
  });
}

export const getRouletteColor = (number: number): string => {
  const index = rouletteWheelNumbers.indexOf(number);
  const i = index >= 0 ? index % 37 : 37 - Math.abs(index % 37);
  return i == 0 || number == null ? "none" : i % 2 == 0 ? "black" : "red";
};
