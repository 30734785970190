import React from "react";
import Modal from "react-modal";

import "../style/ResultModal.css";
import ConfettiAnimation from "./animations/Confetti";
import { WinningBet } from "../scrt/utils";

// Make sure to bind the modal to the app element
Modal.setAppElement("#app");

interface GameModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  winningBets: WinningBet[];
}

const GameModal: React.FC<GameModalProps> = ({
  isOpen,
  onRequestClose,
  winningBets,
}) => {
  const hasWon = winningBets.length > 0;
  console.log(`Displaying modal: ${isOpen}`);
  const getTitle = () => {
    return hasWon
      ? `Congratulations, you won ${winningBets
          .map((bet: WinningBet) => {
            return Number(bet.winnings);
          })
          .reduce((prev, current) => {
            return current + prev;
          }, 0)} uscrt!`
      : "Aww, you lose!";
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="game-modal"
      overlayClassName="game-modal-overlay"
      shouldCloseOnOverlayClick={true}
    >
      <div style={{ position: "relative", zIndex: 20 }}>
        {hasWon && (
          <ConfettiAnimation
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 5,
              width: "70%",
            }}
          />
        )}
        <div style={{ zIndex: 1 }}>
          <h2>{getTitle()}</h2>

          <button className="modal-close-button" onClick={onRequestClose}>
            Go Next
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default GameModal;
