import { TxResponse } from "secretjs";
import { Guess, GuessType } from "../utils/types";

export function parseWinnerFromResponse(tx: TxResponse): string {
  let winningNumber = "";

  if (tx.jsonLog && tx.jsonLog[0]) {
    for (const k in tx.jsonLog[0].events) {
      console.log(tx.jsonLog[0].events[k].type);
      if (
        tx.jsonLog[0].events[k].type.toLocaleLowerCase() ===
        "wasm-wasm-roulette_result"
      ) {
        winningNumber = tx.jsonLog[0].events[k].attributes[1].value;
      }
    }
  }
  return winningNumber;
}

export interface WinningBet {
  bet: Guess;
  winnings: string;
}

export function parseWinningBetsFromResponse(tx: TxResponse): WinningBet[] {
  const winningWagers = [];

  const winners =
    tx.arrayLog?.filter((evt) => {
      return evt.type?.toLocaleLowerCase() === "wasm-winners"; // Add return statement here
    }) || [];
  if (winners.length === 0) {
    return [];
  }

  for (const winner of winners) {
    if (
      winner.key === undefined ||
      winner.value === undefined ||
      winner.key.toLocaleString() === "contract_address"
    ) {
      continue;
    }

    console.log(`Got winner: ${winner.key}: ${winner.value}`);

    winningWagers.push({
      bet: parseGuess(winner.key),
      winnings: winner.value,
    });
  }

  return winningWagers;
}

// converts the string in the attribute of the winnings event to the actual guess that won
function parseGuess(str: string): Guess {
  const guess: Guess = { type: GuessType.EMPTY };

  if (str === "contract_address") {
    return guess;
  }

  const parts = str.split("-");

  switch (parts.length) {
    case 1: {
      const num = parseInt(parts[0], 10);
      if (isNaN(num)) {
        if (parts[0] === "red") {
          guess.type = GuessType.RED;
        } else if (parts[0] === "black") {
          guess.type = GuessType.BLACK;
        } else if (parts[0] === "even") {
          guess.type = GuessType.EVEN;
        } else if (parts[0] === "odd") {
          guess.type = GuessType.ODD;
        }
      } else {
        guess.type = GuessType.NUMBER;
        guess.value = num;
      }
      break;
    }
    case 2: {
      if (parts[0] === "double") {
        guess.type = GuessType.DOUBLE_SPLIT;
        guess.valueSplit = parts.slice(1).map((x) => parseInt(x, 10));
      }
      break;
    }
    case 4: {
      if (parts[0] === "quad") {
        guess.type = GuessType.QUAD_SPLIT;
        guess.valueSplit = parts.slice(1).map((x) => parseInt(x, 10));
      }
      break;
    }
    default: {
      const range = parts.join("-");
      switch (range) {
        case "1-12":
          guess.type = GuessType.NUMBERS_1_12;
          break;
        case "13-24":
          guess.type = GuessType.NUMBERS_2_12;
          break;
        case "25-36":
          guess.type = GuessType.NUMBERS_3_12;
          break;
        case "1-18":
          guess.type = GuessType.NUMBERS_1_18;
          break;
        case "19-36":
          guess.type = GuessType.NUMBERS_19_36;
          break;
        case "odd":
          guess.type = GuessType.ODD;
          break;
        case "even":
          guess.type = GuessType.EVEN;
          break;
        default:
          break;
      }
      break;
    }
  }

  return guess;
}
