// GreetingModal.tsx
import React from "react";
import Modal from "react-modal";
import "../styles.css";

// This line is required for accessibility reasons, and must be set to the id of the root element in your public/index.html file.
Modal.setAppElement("#app");

interface TermsProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const TermsAndConditions: React.FC<TermsProps> = ({
  isOpen,
  onRequestClose,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="game-modal-overlay"
      className="terms-modal"
    >
      <h2>Terms and Conditions</h2>
      <p>
        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE SERVICE IS
        PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT WARRANTIES OF
        ANY KIND, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES
        OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NONINFRINGEMENT,
        OR WARRANTIES ARISING FROM COURSE OF DEALING OR USAGE IN TRADE. WE DO
        NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED, SECURE, ERROR-FREE,
        OR FREE OF DEFECTS.
      </p>
      <p>
        IN NO EVENT SHALL WE, OUR AFFILIATES, OR OUR RESPECTIVE DIRECTORS,
        OFFICERS, EMPLOYEES, AGENTS, OR CONTRACTORS BE LIABLE FOR ANY DIRECT,
        INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE
        DAMAGES, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS,
        LOSS OF TOKENS, OR INTERRUPTION OF SERVICE, ARISING OUT OF OR IN
        CONNECTION WITH THE USE OR INABILITY TO USE THE SERVICE. OUR TOTAL
        LIABILITY FOR ANY CLAIM ARISING OUT OF OR RELATED TO THESE TERMS OR THE
        SERVICE SHALL NOT EXCEED THE AMOUNT OF 100 USD.
      </p>
      <button className="close" onClick={onRequestClose}>
        Close
      </button>
    </Modal>
  );
};

export default TermsAndConditions;
