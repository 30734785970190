import React from "react";
import "../styles.css";
import { toDisplayAddress } from "../utils/address";

interface SecretNetworkAddressProps {
  address: string;
  balance: number | null;
}

const SecretNetworkAddress: React.FC<SecretNetworkAddressProps> = ({
  address,
  balance,
}) => {
  const parsedBalance = balance || "";

  const addressStyle: React.CSSProperties = {
    color: "white",
    fontSize: "14px",
    fontWeight: "bold",
    paddingRight: "20px",
  };

  const balanceStyle: React.CSSProperties = {
    color: "white",
    fontSize: "14px",
    fontWeight: "bold",
  };

  const emptyAddressStyle: React.CSSProperties = {
    ...addressStyle,
    color: "yellow",
    fontSize: "14px",
  };

  const displayAddress = address || "Connect Keplr";
  const displayBalance = parsedBalance || "0";

  return (
    <header className="secret-network-address">
      <p style={address ? addressStyle : emptyAddressStyle}>
        {toDisplayAddress(displayAddress)}
      </p>
      <p style={balanceStyle}>{displayBalance} uscrt</p>
    </header>
  );
};

export default SecretNetworkAddress;
